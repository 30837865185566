.sticky {
    position: fixed;
    top: 0;
    max-width: 100% !important;
    box-shadow: 0px 10px 30px rgb(229 57 53 / 15%) !important;
    z-index: 99;
}

.copyright {
    display: grid;
    grid-template-columns: 3fr 1fr;
    border-top: 1px solid #e5e5e5;
}

.copyright__item1{
    padding-top: 20px;
    padding-bottom: 20px;
}

.copyright__item2 {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    padding-bottom: 20px;
}

.copyright__text {
    font-size: 15px;
    font-family: "Roboto",
    "Helvetica",
    "Arial",
    sans-serif;
    font-weight: 100;
    line-height: 2.66;
    letter-spacing: 0.08333em;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .copyright {
      grid-template-columns: 1fr;
  }

  .copyright__item1 {
      text-align: center;
  }

  .copyright__item2 {
     justify-content: center;
  }

  .copyright__logo {
      text-align: center;
      height: 113px;
  }
}